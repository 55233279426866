import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  background: '#f1f2f6',
  color: '#1e272e',
  text: '#1e272e',
  linkColor: '#ff4757',
  hoverBackground: '#f1f2f6',
  // Network background colors for light theme
  networkParticle: 'rgba(0, 0, 0, 0.5)',
  networkLine: 'rgba(0, 0, 0, 0.15)'
};

export const darkTheme = {
  background: '#161b2c', // More purplish-darkblue
  color: '#f1f2f6',
  text: '#f1f2f6',
  linkColor: '#ff4757',
  hoverBackground: '#232840', // More purplish-darkblue hover
  // Network background colors for dark theme
  networkParticle: 'rgba(255, 255, 255, 0.5)',
  networkLine: 'rgba(255, 255, 255, 0.15)'
};

export const navBarLightTheme = {
  background: 'rgba(241, 242, 246, 0.85)', // Semi-transparent light theme
  backgroundRGB: '241, 242, 246',
  color: '#1e272e',
  text: '#1e272e',
  linkColor: '#ff4757',
  linkColorRGB: '255, 71, 87',
  linkGlowRGB: '255, 71, 87',
  linkHoverBgRGB: '230, 230, 235',
  hoverBackground: 'rgba(196, 197, 201, 0.9)',
};

export const navBarDarkTheme = {
  background: 'rgba(30, 35, 60, 0.85)', // More visible against dark background
  backgroundRGB: '30, 35, 60',
  color: '#f1f2f6',
  text: '#f1f2f6',
  linkColor: '#ff4757',
  linkColorRGB: '255, 71, 87',
  linkGlowRGB: '255, 71, 87',
  linkHoverBgRGB: '50, 56, 85',
  hoverBackground: 'rgba(50, 56, 85, 0.9)',
};

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  /* Hide scrollbar for all elements while allowing scrolling */
  * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  *::-webkit-scrollbar { /* WebKit */
    display: none;
  }
`;