import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './homePage';
import AboutPage from './aboutPage';
import NavBar from './navBar';
import { lightTheme, darkTheme, navBarLightTheme, navBarDarkTheme } from './styles/themes';
import './styles/App.css';

function App() {
  const location = useLocation();
  const [theme, setTheme] = useState('dark');
  const [themetoggled, setthemetoggled] = useState(false);
  // Set initial showNavBar state based on current location
  const [showNavBar, setShowNavBar] = useState(() => {
    return location.pathname === '/about' ? false : true;
  });

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    } else {
      // First-time visitor - set dark mode as default and save to localStorage
      localStorage.setItem('theme', 'dark');
    }

    const handleScroll = () => {
      if (location.pathname !== '/about' || location.state?.fromNavigation) {
        setShowNavBar(window.scrollY < 50);
      }
    };
  
    // Set initial navbar visibility
    if (location.pathname === '/about' && !location.state?.fromNavigation) {
      setShowNavBar(false);
    } else {
      setShowNavBar(window.scrollY < 50);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, location.state?.fromNavigation]);

  useEffect(() => {
    const routeTitles = {
      '/': 'Home',
      '/about': 'About',
    };

    document.title = routeTitles[location.pathname] || 'Home';
  }, [location]);

  useEffect(() => {
    setthemetoggled(false);
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    const currentTheme = theme === 'dark' ? darkTheme : lightTheme;
    const navbarTheme = theme === 'dark' ? navBarDarkTheme : navBarLightTheme;

    root.style.setProperty('--background', currentTheme.background);
    root.style.setProperty('--color', currentTheme.color);
    root.style.setProperty('--text', currentTheme.text);
    root.style.setProperty('--linkColor', currentTheme.linkColor);
    root.style.setProperty('--site-background', currentTheme.background);
    root.style.setProperty('--navbar-background', navbarTheme.background);
    
    // Set the new RGB variables
    root.style.setProperty('--navbar-background-rgb', navbarTheme.backgroundRGB);
    root.style.setProperty('--link-color-rgb', navbarTheme.linkColorRGB);
    root.style.setProperty('--link-glow-rgb', navbarTheme.linkGlowRGB);
    root.style.setProperty('--link-hover-bg-rgb', navbarTheme.linkHoverBgRGB);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    setthemetoggled(true);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div className="App">
      <button className="ThemeToggleButton" onClick={toggleTheme}>
        {theme === 'light' ? '🌙' : '☀️'}
      </button>
      <div className={`AppContainer ${showNavBar ? 'has-navbar' : ''}`}>
        <NavBar show={showNavBar} theme={theme} themeToggled={themetoggled} />
        <div className="ContentContainer">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;