import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './styles/aboutPage.css';

const FadeInSection = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 1 } },
        hidden: { opacity: 0, y: 50 },
      }}
    >
      {children}
    </motion.div>
  );
};

const NetworkBackground = () => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const [theme, setTheme] = useState('dark');
  const [scrollY, setScrollY] = useState(0);
  const particlesRef = useRef([]); // Store particles in a ref to persist between renders
  const mousePositionRef = useRef({ 
    x: window.innerWidth / 2, // Initialize to center of screen
    y: window.innerHeight / 2, 
    moved: false,
    timestamp: Date.now()
  });
  // Add a ref to store connection states between particles
  const connectionsRef = useRef({});
  
  // Listen for theme changes
  useEffect(() => {
    const checkTheme = () => {
      const currentTheme = document.documentElement.style.getPropertyValue('--background');
      // Detect if we're in dark mode by checking if background is darker
      const isDark = (currentTheme && currentTheme.includes('#16')) || 
                    localStorage.getItem('theme') === 'dark';
      setTheme(isDark ? 'dark' : 'light');
    };
    
    // Check theme initially
    checkTheme();
    
    // Set up a MutationObserver to watch for style changes on root element
    const observer = new MutationObserver(checkTheme);
    observer.observe(document.documentElement, { 
      attributes: true, 
      attributeFilter: ['style'] 
    });
    
    // Also listen for storage changes in case theme is toggled in another tab
    const handleStorage = () => {
      const storedTheme = localStorage.getItem('theme');
      setTheme(storedTheme === 'dark' ? 'dark' : 'light');
    };
    
    window.addEventListener('storage', handleStorage);
    
    return () => {
      observer.disconnect();
      window.removeEventListener('storage', handleStorage);
    };
  }, []);
  
  // Track scroll position
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // Network Background Component
  useEffect(() => {
    if (!canvasRef.current) return;
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Colors for particles
    const colorSchemes = {
      light: [
        { stops: ['#3498db', '#8e44ad'], offset: 0 },   // Blue to Purple
        { stops: ['#f1c40f', '#e74c3c'], offset: 120 }, // Yellow to Red
        { stops: ['#2ecc71', '#3498db'], offset: 240 }  // Green to Blue
      ],
      dark: [
        { stops: ['#8e44ad', '#3498db'], offset: 0 },   // Purple to Blue
        { stops: ['#e74c3c', '#f1c40f'], offset: 120 }, // Red to Yellow  
        { stops: ['#3498db', '#2ecc71'], offset: 240 }  // Blue to Green
      ]
    };
    
    // Function to create gradient
    const createGradient = (ctx, x, y, radius, colors) => {
      const gradient = ctx.createRadialGradient(x, y, 0, x, y, radius * 2);
      gradient.addColorStop(0, colors[0]);
      gradient.addColorStop(1, colors[1]);
      return gradient;
    };
    
    // Function to get color based on theme and particle type
    const getParticleColor = (index, opacity) => {
      const schemes = colorSchemes[theme];
      const scheme = schemes[index % schemes.length];
      
      // Convert hex to rgba
      const hexToRgba = (hex, opacity) => {
        // Remove # if present
        hex = hex.replace('#', '');
        
        // Parse the hex values
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        
        // Return rgba format
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      };
      
      return {
        gradient: true,
        stops: [
          hexToRgba(scheme.stops[0], opacity),
          hexToRgba(scheme.stops[1], opacity)
        ],
        offset: scheme.offset
      };
    };
    
    // Function to adjust canvas to screen size
    const adjustCanvas = () => {
      // Reset any transforms before measuring/setting dimensions
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      
      const dpr = window.devicePixelRatio || 1;
      
      // Set actual size in memory (scaled to account for extra pixel density)
      canvas.width = window.innerWidth * dpr;
      canvas.height = window.innerHeight * dpr;
      
      // CSS size remains the same (full viewport)
      canvas.style.width = window.innerWidth + 'px';
      canvas.style.height = window.innerHeight + 'px';
      
      // Scale all drawing operations by the dpr
      ctx.scale(dpr, dpr);
    };
    
    // Create particles only if they don't exist yet
    const createParticles = () => {
      if (particlesRef.current.length === 0) {
        // Create more particles for a denser effect (ALOT OF PARTICLES)
        const particleCount = Math.min(Math.floor(window.innerWidth / 15), 150) * 2;
        
        for (let i = 0; i < particleCount; i++) {
          particlesRef.current.push({
            x: Math.random() * window.innerWidth,
            y: Math.random() * (window.innerHeight * 2), // Doubled height for scrolling effect
            radius: Math.random() * 3 + 1.5,
            speedX: (Math.random() - 0.5) * 0.3,
            speedY: (Math.random() - 0.5) * 0.3,
            opacity: Math.random() * 0.5 + 0.3,
            colorIndex: Math.floor(Math.random() * 3),
            rotation: Math.random() * 360,
            // Unique frequency and phase for gradual movement
            freqX: Math.random() * 0.002 + 0.001,
            freqY: Math.random() * 0.002 + 0.001,
            phaseX: Math.random() * Math.PI * 2,
            phaseY: Math.random() * Math.PI * 2,
            amplitude: Math.random() * 0.2 + 0.1,
            connections: {}, // Track connections to other particles
            pulsePhase: Math.random() * Math.PI * 2, // Random phase for pulse effect
            movedSignificantly: false, // Track if particle moved significantly
            mouseInfluenced: false // Track if particle is influenced by mouse
          });
        }
      }
    };
    
    // Handle mouse move
    const handleMouseMove = (e) => {
      // Only update position if it's significantly different to avoid recalculating connections unnecessarily
      const newX = e.clientX;
      const newY = e.clientY;
      
      // Calculate distance from current mouse position
      const dx = newX - mousePositionRef.current.x;
      const dy = newY - mousePositionRef.current.y;
      const moveDistance = Math.sqrt(dx * dx + dy * dy);
      
      // Only update if moved more than 2px to reduce calculation overhead
      if (moveDistance > 2) {
      mousePositionRef.current = { 
          x: newX, 
          y: newY,
          moved: true, // Flag that mouse has moved
          timestamp: Date.now() // Track when the mouse last moved
        };
      }
    };
    
    // Handle mouse leave
    const handleMouseLeave = () => {
      // Reset mouse influence when mouse leaves the canvas
      mousePositionRef.current.moved = false;
    };
    
    // Handle resize - crucial for fullscreen
    const handleResize = () => {
      adjustCanvas();
      // Only recreate particles on resize if needed
      if (particlesRef.current.length === 0) {
        createParticles();
      } else {
        // Adjust existing particles to new window size if needed
        particlesRef.current.forEach(particle => {
          if (particle.x > window.innerWidth) particle.x = window.innerWidth * Math.random();
          if (particle.y > window.innerHeight * 2) particle.y = window.innerHeight * 2 * Math.random();
        });
      }
    };
    
    // Initialize canvas and particles
    adjustCanvas();
    createParticles();
    
    // Generate unique connection key for two particles
    const getConnectionKey = (i1, i2) => {
      // Always use the smaller index first to ensure consistent keys
      return i1 < i2 ? `${i1}-${i2}` : `${i2}-${i1}`;
    };
    
    // Animation loop
    const animate = () => {
      if (!canvasRef.current) return;
      
      // Clear canvas completely
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      
      // Calculate the scroll offset for parallax effect
      const scrollOffset = scrollY * 0.5;
      
      // Create a set to track active connections in this frame
      const activeConnections = new Set();
      
      // Time for pulse effects
      const time = Date.now() * 0.001;
      
      // Check if mouse has moved recently
      const mouseRecentlyMoved = mousePositionRef.current.moved && 
                                 (Date.now() - mousePositionRef.current.timestamp < 100);
      
      // Draw and update particles
      particlesRef.current.forEach((particle, index) => {
        // Calculate scroll-adjusted y position
        const adjustedY = (particle.y - scrollOffset) % (window.innerHeight * 2);
        const normalizedY = adjustedY < 0 ? adjustedY + window.innerHeight * 2 : adjustedY;
        
        // Only draw particles that are in view (with some buffer)
        if (normalizedY > -50 && normalizedY < window.innerHeight + 50) {
          // Calculate distance from mouse
          const dx = mousePositionRef.current.x - particle.x;
          const dy = mousePositionRef.current.y - normalizedY;
          const distance = Math.sqrt(dx * dx + dy * dy);
          const maxDistance = 50;
          
          // Reset mouse influence flag
          particle.mouseInfluenced = false;
          
          // Apply forces based on mouse position
          if (distance < maxDistance) {
            const force = (maxDistance - distance) / maxDistance * 2;
            // Reverse the direction to push particles away from the cursor
            particle.speedX -= dx * force * 0.002; // Reduced strength for subtler effect
            particle.speedY -= dy * force * 0.002; // Reduced strength for subtler effect
            
            // Flag this particle as being influenced by mouse
            particle.mouseInfluenced = true;
          }
          
          // Track changes in position that might affect connections
          const prevX = particle.x;
          const prevY = particle.y;
          
          // Apply friction
          particle.speedX *= 0.98;
          particle.speedY *= 0.98;
          
          // Add gradual autonomous movement using sine waves
          // This creates a smooth, gentle drift that's unique for each particle
          const gradualX = Math.sin(time * particle.freqX + particle.phaseX) * 0.003 * particle.amplitude;
          const gradualY = Math.sin(time * particle.freqY + particle.phaseY) * 0.003 * particle.amplitude;
          particle.speedX += gradualX;
          particle.speedY += gradualY;
          
          // Update position
          particle.x += particle.speedX;
          particle.y += particle.speedY;
          
          // Calculate movement magnitude to detect if particles moved significantly
          const moveX = particle.x - prevX;
          const moveY = particle.y - prevY;
          const moveMagnitude = Math.sqrt(moveX * moveX + moveY * moveY);
          
          // Flag to track if particle moved significantly due to mouse interaction or other forces
          // Consider mouse movements more important if they're recent
          particle.movedSignificantly = moveMagnitude > 0.5 || 
                                       (mouseRecentlyMoved && particle.mouseInfluenced);
          
          // Rotation effect
          particle.rotation += 0.2;
          
          // Wrap around edges horizontally
          if (particle.x < 0) {
            // Before wrapping, mark all connections for instant breaking
            Object.keys(connectionsRef.current).forEach(key => {
              if (key.split('-').includes(index.toString())) {
                connectionsRef.current[key].instantBreak = true;
              }
            });
            particle.x = window.innerWidth;
          }
          if (particle.x > window.innerWidth) {
            // Before wrapping, mark all connections for instant breaking
            Object.keys(connectionsRef.current).forEach(key => {
              if (key.split('-').includes(index.toString())) {
                connectionsRef.current[key].instantBreak = true;
              }
            });
            particle.x = 0;
          }
          
          // Wrap around edges vertically (accounting for the extended height)
          if (particle.y < 0) {
            // Before wrapping, mark all connections for instant breaking
            Object.keys(connectionsRef.current).forEach(key => {
              if (key.split('-').includes(index.toString())) {
                connectionsRef.current[key].instantBreak = true;
              }
            });
            particle.y = window.innerHeight * 2;
          }
          if (particle.y > window.innerHeight * 2) {
            // Before wrapping, mark all connections for instant breaking
            Object.keys(connectionsRef.current).forEach(key => {
              if (key.split('-').includes(index.toString())) {
                connectionsRef.current[key].instantBreak = true;
              }
            });
            particle.y = 0;
          }
          
          // Get color for this particle
          const colorData = getParticleColor(particle.colorIndex, particle.opacity);
          
          // Draw particle with gradient
          ctx.save();
          ctx.beginPath();
          ctx.arc(particle.x, normalizedY, particle.radius, 0, Math.PI * 2);
          
          if (colorData.gradient) {
            const gradient = createGradient(ctx, particle.x, normalizedY, particle.radius, colorData.stops);
            ctx.fillStyle = gradient;
          } else {
            ctx.fillStyle = colorData;
          }
          
          ctx.fill();
          ctx.restore();
          
          // Draw connections with optimization
          for (let j = index + 1; j < particlesRef.current.length; j++) {
            const p2 = particlesRef.current[j];
            
            // Calculate scroll-adjusted y position for the other particle
            const p2AdjustedY = (p2.y - scrollOffset) % (window.innerHeight * 2);
            const p2NormalizedY = p2AdjustedY < 0 ? p2AdjustedY + window.innerHeight * 2 : p2AdjustedY;
            
            // Only check connections if both particles are on screen
            if (p2NormalizedY > -50 && p2NormalizedY < window.innerHeight + 50) {
              const dx = particle.x - p2.x;
              const dy = normalizedY - p2NormalizedY;
              const distance = Math.sqrt(dx * dx + dy * dy);
              const connectionKey = getConnectionKey(index, j);
              
              // Add to active connections set
              activeConnections.add(connectionKey);
              
              // Create connection object if it doesn't exist yet
              if (!connectionsRef.current[connectionKey]) {
                connectionsRef.current[connectionKey] = {
                  progress: 0,
                  active: false,
                  lastActive: Date.now(),
                  // Track distance to handle connections that hover near threshold
                  lastDistance: distance,
                  // Track whether this is a mouse-influenced connection
                  mouseInfluenced: false,
                  // Track last mouse state to detect transitions
                  lastMouseInfluenced: false,
                  // Track when the connection was last mouse influenced
                  lastMouseTimestamp: 0,
                  // Track disconnection animation - separate from connection progress
                  disconnecting: false,
                  disconnectProgress: 0,
                  // Flag for instant breaking when particles wrap around screen edges
                  instantBreak: false
                };
              }
              
              const connection = connectionsRef.current[connectionKey];
              
              // Update the last known distance
              connection.lastDistance = distance;
              
              // Check if mouse influence has changed
              const currentMouseInfluence = (particle.mouseInfluenced || p2.mouseInfluenced) && mouseRecentlyMoved;
              
              // Detect reconnection scenarios - when a connection was previously active but broken
              const wasRecentlyActive = Date.now() - connection.lastActive < 1000;
              const isMakingNewConnection = currentMouseInfluence && !connection.active && wasRecentlyActive;
              
              // If mouse influence has just started, consider it a new connection attempt
              if (currentMouseInfluence && !connection.mouseInfluenced) {
                // Store timestamp of when mouse influence began
                connection.lastMouseTimestamp = Date.now();
              }
              
              // Force reset progress if it's a new mouse-driven connection attempt
              if (isMakingNewConnection) {
                // Reset to early stage to ensure visible animation
                connection.progress = Math.min(connection.progress, 0.15);
              }
              
              // Store last state for next frame comparison
              connection.lastMouseInfluenced = connection.mouseInfluenced;
              
              // Update current mouse influence state
              connection.mouseInfluenced = currentMouseInfluence;
              
              // Start connecting if within threshold distance
              // We use a slightly larger threshold for maintaining connections to avoid flickering
              const connectThreshold = 100;
              const disconnectThreshold = 110; // Slightly higher to create hysteresis
              
              // Use different thresholds for connecting and disconnecting to avoid flickering
              const isWithinThreshold = connection.active ? 
                distance < disconnectThreshold : 
                distance < connectThreshold;
              
              if (isWithinThreshold) {
                // Connection is becoming active - if it was disconnecting, reset that state
                if (connection.disconnecting) {
                  connection.disconnecting = false;
                  connection.disconnectProgress = 0;
                }
                
                // Connection is active
                connection.active = true;
                connection.lastActive = Date.now();
                
                // Check if either particle moved significantly due to mouse or other forces
                const mouseMovement = connection.mouseInfluenced;
                const rapidMovement = particle.movedSignificantly || p2.movedSignificantly;
                
                // For connections that were inactive but are becoming active again due to mouse, 
                // reset progress to create smooth animation
                const isReconnecting = connection.lastActive - connection.lastMouseTimestamp < 100 && 
                                      mouseMovement && 
                                      connection.progress < 0.3;
                                    
                if (isReconnecting) {
                  // Reset progress for a fresh animation on reconnection
                  connection.progress = Math.min(connection.progress, 0.1);
                }
                
                // Determine growth rate based on movement - slower for natural connections,
                // Prioritize mouse influence over general movement
                const baseGrowthRate = 0.015; // Slower default for more visible growth
                
                let growthRate = baseGrowthRate;
                
                // Mouse-influenced connections appear faster but still with visible growth
                if (mouseMovement) {
                  growthRate = 0.04; // Fast enough for interaction, slow enough to see
                } else if (rapidMovement) {
                  growthRate = 0.025; // Slightly faster for rapid autonomous movement
                }
                
                // If connection is just starting, ensure it begins gradually even with mouse
                if (connection.progress < 0.1) {
                  growthRate = Math.min(growthRate, 0.02); // Cap initial growth rate
                }
                
                // Apply the appropriate growth rate
                connection.progress = Math.min(1, connection.progress + growthRate);
                
                // Add pulse animation when the connection is fully formed
                let pulseEffect = 1;
                if (connection.progress >= 0.98) { // Use slightly lower threshold for pulse effect
                  // Create a pulse effect by oscillating the line width
                  const pulseFrequency = 2;
                  const pulsePhase = (particle.pulsePhase + p2.pulsePhase) / 2;
                  pulseEffect = 0.8 + 0.2 * Math.sin(time * pulseFrequency + pulsePhase);
                }
                
                // Get colors for both particles for the gradient
                const color1 = getParticleColor(particle.colorIndex, particle.opacity * Math.max(0.4, connection.progress) * pulseEffect);
                const color2 = getParticleColor(p2.colorIndex, p2.opacity * Math.max(0.4, connection.progress) * pulseEffect);
                
                // Calculate the animation points
                const midX = particle.x + (p2.x - particle.x) * 0.5;
                const midY = normalizedY + (p2NormalizedY - normalizedY) * 0.5;
                
                // Calculate how far to draw the line based on progress - make this more linear and visible
                const lineProgress = connection.progress;
                
                // Use linear progress value to determine how far each line should extend
                const p1TargetX = particle.x + (midX - particle.x) * lineProgress;
                const p1TargetY = normalizedY + (midY - normalizedY) * lineProgress;
                const p2TargetX = p2.x + (midX - p2.x) * lineProgress;
                const p2TargetY = p2NormalizedY + (midY - p2NormalizedY) * lineProgress;
                
                // Make lines more visible during growth 
                const startOpacity = Math.min(0.9, 0.3 + connection.progress * 0.7);
                
                // Draw first half of the connection
                const lineGradient1 = ctx.createLinearGradient(
                  particle.x, normalizedY, 
                  p1TargetX, p1TargetY
                );
                lineGradient1.addColorStop(0, color1.stops[0].replace(/[\d.]+\)$/, `${startOpacity})`));
                lineGradient1.addColorStop(1, `rgba(255,255,255,${startOpacity * 0.3})`);
                
                ctx.beginPath();
                ctx.moveTo(particle.x, normalizedY);
                ctx.lineTo(p1TargetX, p1TargetY);
                
                // Make line thicker to be more visible, especially during growth
                const baseWidth = Math.max(1, 2 * (1 - distance / 100));
                // Ensure even partial connections are visible with a minimum width
                const lineWidth = Math.max(baseWidth * 0.5, baseWidth * connection.progress * pulseEffect);
                ctx.strokeStyle = lineGradient1;
                ctx.lineWidth = lineWidth;
                ctx.stroke();
                
                // Add a small dot at the end of the line to visualize growth
                if (connection.progress > 0 && connection.progress < 1) {
                  ctx.beginPath();
                  ctx.arc(p1TargetX, p1TargetY, lineWidth * 1.2, 0, Math.PI * 2);
                  ctx.fillStyle = 'rgba(255, 255, 255, 0.4)';
                  ctx.fill();
                }
                
                // Draw second half of the connection
                const lineGradient2 = ctx.createLinearGradient(
                  p2.x, p2NormalizedY,
                  p2TargetX, p2TargetY
                );
                lineGradient2.addColorStop(0, color2.stops[0].replace(/[\d.]+\)$/, `${startOpacity})`));
                lineGradient2.addColorStop(1, `rgba(255,255,255,${startOpacity * 0.3})`);
                
                ctx.beginPath();
                ctx.moveTo(p2.x, p2NormalizedY);
                ctx.lineTo(p2TargetX, p2TargetY);
                ctx.strokeStyle = lineGradient2;
                ctx.stroke();
                
                // Add a small dot at the end of the second line segment
                if (connection.progress > 0 && connection.progress < 1) {
                  ctx.beginPath();
                  ctx.arc(p2TargetX, p2TargetY, lineWidth * 1.2, 0, Math.PI * 2);
                  ctx.fillStyle = 'rgba(255, 255, 255, 0.4)';
                  ctx.fill();
                }
                
                // Add a small glow effect at the connection point if progress is complete
                if (connection.progress > 0.9) {
                  // Pulse the glow as well
                  const glowRadius = lineWidth * 2 * pulseEffect;
                  ctx.beginPath();
                  ctx.arc(midX, midY, glowRadius, 0, Math.PI * 2);
                  
                  // Use a color that matches the theme with pulsing opacity
                  const glowOpacity = 0.1 + 0.1 * pulseEffect;
                  const glowColor = theme === 'light' 
                    ? `rgba(52, 152, 219, ${glowOpacity})` 
                    : `rgba(142, 68, 173, ${glowOpacity})`;
                  
                  ctx.fillStyle = glowColor;
                  ctx.fill();
                }
                
                ctx.lineWidth = 1; // Reset line width
              } else {
                // Connection is no longer active
                if (connection.active) {
                  // If marked for instant break (particle crossed screen edge), reset immediately
                  if (connection.instantBreak) {
                    connection.progress = 0;
                    connection.disconnecting = false;
                    connection.disconnectProgress = 0;
                    connection.instantBreak = false;
                  } else {
                    // Normal disconnection with animation
                    // Start disconnection animation when the connection first becomes inactive
                    connection.disconnecting = true;
                    connection.disconnectProgress = 0;
                  }
                }
                
                connection.active = false;
                
                // Only perform disconnection animation if not marked for instant break
                if (!connection.instantBreak && connection.disconnecting && connection.progress > 0) {
                  // Determine decay rate - much slower for a more visible animation
                  const decayRate = connection.mouseInfluenced ? 0.01 : 0.005;
                  
                  // Increment disconnect progress (0 to 1)
                  connection.disconnectProgress = Math.min(1, connection.disconnectProgress + decayRate);
                  
                  // Calculate the animation points for retraction
                  const midX = particle.x + (p2.x - particle.x) * 0.5;
                  const midY = normalizedY + (p2NormalizedY - normalizedY) * 0.5;
                  
                  // Use easing function to make the retraction more natural
                  // Use easeOutQuint for slower start and gradual acceleration
                  const easeOutQuint = t => 1 - Math.pow(1 - t, 5);
                  const easedRetraction = easeOutQuint(connection.disconnectProgress);
                  
                  // For the reverse of connection animation, we want to:
                  // 1. Keep the lines connected at full length in the center
                  // 2. Gradually shrink them back toward their particles
                  
                  // Calculate how much of the original connection remains as disconnection progresses
                  // This is essentially the inverse of the connection progress
                  const remainingConnectionLength = 1 - easedRetraction;
                  
                  // Calculate the starting points of the lines (at the nodes, these don't change)
                  const p1StartX = particle.x;
                  const p1StartY = normalizedY;
                  const p2StartX = p2.x;
                  const p2StartY = p2NormalizedY;
                  
                  // Calculate the endpoints of the lines (starting at midpoint, retreating toward nodes)
                  // For first line: at disconnect 0%, endpoint is at midpoint, at 100% endpoint equals startpoint
                  const p1EndX = particle.x + (midX - particle.x) * remainingConnectionLength;
                  const p1EndY = normalizedY + (midY - normalizedY) * remainingConnectionLength;
                  
                  // For second line: similar calculation but from particle 2
                  const p2EndX = p2.x + (midX - p2.x) * remainingConnectionLength;
                  const p2EndY = p2NormalizedY + (midY - p2NormalizedY) * remainingConnectionLength;
                  
                  // Calculate opacity based on disconnection progress
                  const lineOpacity = Math.min(0.9, 0.3 + (1 - easedRetraction) * 0.7);
                  
                  // Get colors for the retracting lines
                  const color1 = getParticleColor(particle.colorIndex, particle.opacity * (1 - easedRetraction));
                  const color2 = getParticleColor(p2.colorIndex, p2.opacity * (1 - easedRetraction));
                  
                  // Calculate base width for lines (shrinks as we disconnect)
                  const baseWidthMultiplier = 1 - easedRetraction;
                  const baseWidth = Math.max(0.5, 1.5 * (1 - distance / 100)) * baseWidthMultiplier;
                  
                  // Draw the lines if they still have length
                  if (connection.disconnectProgress < 1) {
                    // First line
                    const p1Length = Math.sqrt(
                      Math.pow(p1EndX - p1StartX, 2) + 
                      Math.pow(p1EndY - p1StartY, 2)
                    );
                    
                    if (p1Length > 0.5) {
                      const lineGradient1 = ctx.createLinearGradient(
                        p1StartX, p1StartY,
                        p1EndX, p1EndY
                      );
                      
                      lineGradient1.addColorStop(0, color1.stops[0].replace(/[\d.]+\)$/, `${lineOpacity})`));
                      lineGradient1.addColorStop(1, `rgba(255,255,255,${lineOpacity * 0.3})`);
                      
                      ctx.beginPath();
                      ctx.moveTo(p1StartX, p1StartY);
                      ctx.lineTo(p1EndX, p1EndY);
                      ctx.strokeStyle = lineGradient1;
                      ctx.lineWidth = baseWidth;
                      ctx.stroke();
                      
                      // Add a small dot at the end of the line to visualize the retraction
                      ctx.beginPath();
                      ctx.arc(p1EndX, p1EndY, baseWidth * 1.2, 0, Math.PI * 2);
                      ctx.fillStyle = `rgba(255, 255, 255, ${lineOpacity * 0.6})`;
                      ctx.fill();
                    }
                    
                    // Second line
                    const p2Length = Math.sqrt(
                      Math.pow(p2EndX - p2StartX, 2) + 
                      Math.pow(p2EndY - p2StartY, 2)
                    );
                    
                    if (p2Length > 0.5) {
                      const lineGradient2 = ctx.createLinearGradient(
                        p2StartX, p2StartY,
                        p2EndX, p2EndY
                      );
                      
                      lineGradient2.addColorStop(0, color2.stops[0].replace(/[\d.]+\)$/, `${lineOpacity})`));
                      lineGradient2.addColorStop(1, `rgba(255,255,255,${lineOpacity * 0.3})`);
                      
                      ctx.beginPath();
                      ctx.moveTo(p2StartX, p2StartY);
                      ctx.lineTo(p2EndX, p2EndY);
                      ctx.strokeStyle = lineGradient2;
                      ctx.lineWidth = baseWidth;
                      ctx.stroke();
                      
                      // Add a small dot at the end of the second line
                      ctx.beginPath();
                      ctx.arc(p2EndX, p2EndY, baseWidth * 1.2, 0, Math.PI * 2);
                      ctx.fillStyle = `rgba(255, 255, 255, ${lineOpacity * 0.6})`;
                      ctx.fill();
                    }
                    
                    // Add a fading glow at the midpoint where the connection is breaking
                    // Early in the disconnection process, this helps show the central point
                    if (connection.disconnectProgress < 0.7) {
                      const fadeGlowRadius = baseWidth * 2 * (1 - connection.disconnectProgress * 1.4);
                      if (fadeGlowRadius > 0.2) {
                        ctx.beginPath();
                        ctx.arc(midX, midY, fadeGlowRadius, 0, Math.PI * 2);
                        
                        // Use a color that matches the theme with fading opacity
                        const glowOpacity = 0.25 * (1 - connection.disconnectProgress * 1.4);
                        const glowColor = theme === 'light' 
                          ? `rgba(52, 152, 219, ${glowOpacity})` 
                          : `rgba(142, 68, 173, ${glowOpacity})`;
                        
                        ctx.fillStyle = glowColor;
                        ctx.fill();
                      }
                    }
                    
                    // Reset line width
                    ctx.lineWidth = 1;
                  }
                  
                  // When disconnect animation completes, reset the connection
                  if (connection.disconnectProgress >= 1) {
                    connection.disconnecting = false;
                    connection.progress = 0;
                    connection.disconnectProgress = 0;
                    
                    // Force reset for mouse-influenced connections
                    if (connection.mouseInfluenced) {
                      connection.mouseInfluenced = false;
                    }
                  }
                } else if (!connection.disconnecting) {
                  // For connections not marked for disconnection animation or instant break
                  // Force the progress to reset completely for mouse-influenced connections
                  // This ensures they grow smoothly next time they connect
                  if (connection.mouseInfluenced) {
                    connection.progress = 0;
                  }
                }
              }
            }
          }
        }
      });
      
      // Decay inactive connections
      Object.keys(connectionsRef.current).forEach(key => {
        const connection = connectionsRef.current[key];
        
        // If the connection is not active in this frame, start decaying it
        if (!activeConnections.has(key) && connection.progress > 0 && !connection.disconnecting) {
          // If the connection isn't using the disconnect animation, use simple fade
          // Use faster decay for mouse-influenced connections to ensure they reset completely
          const decayRate = connection.mouseInfluenced ? 0.04 : 0.02;
          connection.progress = Math.max(0, connection.progress - decayRate);
          
          // Reset mouseInfluenced when fully disconnected
          if (connection.progress === 0) {
            connection.mouseInfluenced = false;
          }
          
          // If progress is 0, consider removing the connection to save memory
          if (connection.progress === 0 && Date.now() - connection.lastActive > 5000) {
            delete connectionsRef.current[key];
          }
        }
      });
      
      animationRef.current = requestAnimationFrame(animate);
    };
    
    // Set up event listeners
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);
    window.addEventListener('mouseleave', handleMouseLeave);
    
    // Start animation
    animate();
    
    // Cleanup function
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mouseleave', handleMouseLeave);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [scrollY, theme]); // Depend on both scrollY and theme for color changes only
  
  return (
    <canvas 
      ref={canvasRef} 
      className="network-background"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw', 
        height: '100vh',
        zIndex: -1,
        pointerEvents: 'none',
      }}
    />
  );
};

function ContactPage() {
  return (
    <>
      <NetworkBackground />
      <div className="about-content">
        <header className="AboutPageHeader">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <h1 className="AboutPageName">Renato Liski</h1>
            <p className="AboutPageSubTitle">Programming | Electrical Engineering | Robotics | Computer Vision</p>
          </motion.div>
        </header>

        <FadeInSection>
          <section className="AboutPageSection">
            <h2 className="AboutPageSectionTitle">About Me</h2>
            <div className="AboutPageSeparator" />
            <ul className="AboutPageList">
              <li className="AboutPageListItem">
                I am a dedicated student pursuing a Bachelor of Engineering in Electrical and Automation Engineering, with a bigger lean on the automation part. My studies involve not only mastering automation systems but also engaging in group work and learning to collaborate effectively as part of a group.
              </li>
              <li className="AboutPageListItem">
                Outside of my studies, I enjoy coding, exploring robotics and artificial intelligence concepts. In my free time, I also find pleasure in playing video games, which helps me unwind.
              </li>
            </ul>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section className="AboutPageSection">
            <h2 className="AboutPageSectionTitle">Education</h2>
            <div className="AboutPageSeparator" />
            <ul className="AboutPageList">
              <li className="AboutPageListItem">Mechanical Engineering - Satakunta University of Applied Sciences (2021-2022)</li>
              <li className="AboutPageListItem">Electrical Engineering and Automation, RoboAI Academy - Satakunta University of Applied Sciences (2022-2025)</li>
            </ul>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section className="AboutPageSection">
            <h2 className="AboutPageSectionTitle">Skills</h2>
            <div className="AboutPageSeparator" />
            <ul className="AboutPageList">
              <li className="AboutPageListItem">Computer Vision: OpenCV, MVTEC Halcon</li>
              <li className="AboutPageListItem">Programming Languages: C, C++, Python, JavaScript, Ruby</li>
              <li className="AboutPageListItem">Microcontrollers: Arduino, Raspberry Pi</li>
              <li className="AboutPageListItem">Robotics: Autonomous Systems, Sensors Integration, Logic Programming</li>
              <li className="AboutPageListItem">Electrical Engineering: Circuit Design, PCB Layout</li>
            </ul>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section className="AboutPageSection">
            <h2 className="AboutPageSectionTitle">Projects</h2>
            <div className="AboutPageSeparator" />
            <ul className="AboutPageList">
              <li className="AboutPageListItem">Automated Beer Can Weighing System - Designed and programmed an affordable solution for a brewery to weigh cans of beer, display them on an OLED screen and automatically discard the ones below a specified weight, using an Arduino and pneumatics.</li>
              <li className="AboutPageListItem">Bolt Machine Vision System - Designed and taught a machine vision solution to detect bolts and identify missing bolts or empty bolt holes in steel objects on a factory line, ensuring quality control.</li>
            </ul>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section className="AboutPageSection">
            <h2 className="AboutPageSectionTitle">Contact Me</h2>
            <div className="AboutPageSeparator" />
            <p className="AboutPageText">Email: renato.liski@gmail.com</p>
            <p className="AboutPageText">GitHub: <a className="AboutPageContactLink" href="https://github.com/renskursa" target="_blank" rel="noreferrer">https://github.com/renskursa</a></p>
          </section>
        </FadeInSection>
      </div>
    </>
  );
}

export default ContactPage;