import React, { useRef, useMemo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import './styles/navBar.css';

const BaseNavLink = styled.a.attrs({ className: 'base-nav-link' })``;

const NavLink = styled(BaseNavLink).attrs({ as: Link })`
  width: 100%;
  display: block;
  text-align: center;
`;

const ExternalNavLink = styled(BaseNavLink).attrs({ as: 'a' })`
  width: 100%;
  display: block;
  text-align: center;
`;

// Separate component for the indicator to isolate animation behavior
const NavBarIndicator = ({ isActive }) => {
  return isActive ? (
    <motion.div
      className="active-dot"
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '-5px',
        width: '100%',
        height: '3px',
        margin: '0 auto',
        maxWidth: '24px',
        background: 'linear-gradient(90deg, rgba(255,71,87,0.4) 0%, rgba(255,71,87,1) 50%, rgba(255,71,87,0.4) 100%)',
        borderRadius: '4px',
        boxShadow: '0 2px 10px rgba(255, 71, 87, 0.5)'
      }}
      initial={{ opacity: 0, scaleX: 0.5 }}
      animate={{ opacity: 1, scaleX: 1 }}
      exit={{ opacity: 0, scaleX: 0.5 }}
      transition={{
        type: "spring",
        stiffness: 500,
        damping: 30
      }}
    />
  ) : null;
};

const NavBar = ({ show, theme, themeToggled }) => {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  // Define responsive link text based on screen width
  const links = useMemo(() => {
    if (windowWidth <= 400) {
      return [
        { path: '/', label: 'Home' },
        { path: 'https://renskursa.github.io/Simple-3D-Model-Viewer/', label: '3D View', external: true, openInNewTab: true },
        { path: '/about', label: 'About', external: false, openInNewTab: false, state: { fromNavigation: true } },
      ];
    } else if (windowWidth <= 480) {
      return [
        { path: '/', label: 'Home' },
        { path: 'https://renskursa.github.io/Simple-3D-Model-Viewer/', label: '3D Viewer', external: true, openInNewTab: true },
        { path: '/about', label: 'About', external: false, openInNewTab: false, state: { fromNavigation: true } },
      ];
    } else {
      return [
        { path: '/', label: 'Home' },
        { path: 'https://renskursa.github.io/Simple-3D-Model-Viewer/', label: '3D Model Viewer', external: true, openInNewTab: true },
        { path: '/about', label: 'About Me', external: false, openInNewTab: false, state: { fromNavigation: true } },
      ];
    }
  }, [windowWidth]);
  
  const activeIndex = useMemo(() => links.findIndex(link => link.path === location.pathname), [links, location.pathname]);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const linkRefs = useRef([]);
  const navLinksRef = useRef(null);
  
  // Check screen size and update state
  useEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      setIsMobile(width <= 768);
    };
    
    // Check on initial load
    checkScreenSize();
    
    // Check on resize
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  
  // Initialize refs array on each render to avoid stale references
  useEffect(() => {
    linkRefs.current = linkRefs.current.slice(0, links.length);
  }, [links.length]);

  useEffect(() => {
    // Remove initial render flag after small delay
    if (isInitialRender) {
      setTimeout(() => {
        setIsInitialRender(false);
      }, 100);
    }
  }, [isInitialRender]);

  // Animation variants for the NavBar container
  const navContainerVariants = {
    hidden: { 
      opacity: 0,
      y: -20
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: { 
        duration: 0.5,
        ease: "easeOut",
        staggerChildren: 0.1
      }
    }
  };

  // Animation variants for individual links
  const linkVariants = {
    hidden: { 
      opacity: 0,
      y: -10 
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20
      }
    }
  };

  // Function to calculate the appropriate min width based on screen size
  const getMinWidth = () => {
    const width = window.innerWidth;
    if (width <= 400) return '55px';
    if (width <= 480) return '60px';
    if (width <= 600) return '65px';
    if (width <= 768) return '70px';
    return '80px';
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.nav 
          className={`nav-bar-container ${themeToggled ? 'theme-toggled' : ''}`}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={navContainerVariants}
        >
          <div className="nav-links" ref={navLinksRef}>
            {links.map((link, index) => (
              <motion.div 
                key={index} 
                variants={linkVariants} 
                ref={el => (linkRefs.current[index] = el)}
                whileHover={!isMobile ? { scale: 1.05 } : {}}
                whileTap={!isMobile ? { scale: 0.95 } : {}}
                style={{ 
                  position: 'relative',
                  minWidth: getMinWidth(),
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {link.external ? (
                  <ExternalNavLink
                    href={link.path}
                    target={link.openInNewTab ? "_blank" : "_self"}
                    rel={link.openInNewTab ? "noopener noreferrer" : ""}
                  >
                    {link.label}
                  </ExternalNavLink>
                ) : (
                  <NavLink
                    to={link.path}
                    state={link.state}
                    target={link.openInNewTab ? "_blank" : undefined}
                    rel={link.openInNewTab ? "noopener noreferrer" : undefined}
                    className={location.pathname === link.path ? 'active' : ''}
                  >
                    {link.label}
                  </NavLink>
                )}
                
                {/* Use the dedicated indicator component */}
                <NavBarIndicator isActive={index === activeIndex} />
              </motion.div>
            ))}
          </div>
        </motion.nav>
      )}
    </AnimatePresence>
  );
};

export default NavBar;
